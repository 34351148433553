
import { dealTime } from '@/utils/utils'
import { defineComponent, ref, onMounted, cloneVNode } from 'vue'
import { useStore } from '@/store'
import { getMeetingRoomList, createMeeting, conflictMeeting } from '@/api/meet'
import { useRoute, useRouter } from 'vue-router'
import { Toast } from 'vant'
import moment from 'moment'
export default defineComponent({
  setup() {
    interface meetingRoomObj {
      id: string|number,
      meetingRoomName: string,
    }
    const router = useRouter()
    const meetingroom = ref()
    const route = useRoute() as any
    const store = useStore()
    const tel = ref()
    const result = ref('')
    const bookingPerson = ref('')
    const meetingName = ref('')
    const meetingNum = ref(2)
    const meetingStartTimeStr = ref(route.query.time)
    const meetingEndTimeStr = ref('')
    const meetRoom = ref(route.query.roomId)
    const showPicker = ref(false)
    const showMeetPicker = ref(false)
    const tabList = ref() as any
    const columns = ref()
    const startOrend = ref()
    const currentTime = ref()
    const filter = (type:any, options:any) => {
      if (type === 'minute') {
        return options.filter((option:any) => option % 30 === 0)
      }
      if (type === 'hour') {
        return options.filter((option:any) => option >= 8)
      }
      return options
    }
    onMounted(async () => {
      // console.log(store.state.siteId)
      getMeetRoomList()
      if (route.query.time) {
        meetingStartTimeStr.value = (route.query.time.slice(0, route.query.time.indexOf('T')) + route.query.time.slice(route.query.time.indexOf('T'), route.query.time.indexOf('~'))).replace('T', ' ').slice(0, 16)
        meetingEndTimeStr.value = (route.query.time.slice(0, route.query.time.indexOf('T')) + route.query.time.slice(route.query.time.indexOf('~'))).replace('~', ' ').slice(0, 16)
      }
      // console.log(meetingStartTimeStr.value)
    })
    const meetonConfirm = (e : string) => {
      console.log(e)
      meetRoom.value = e
      showMeetPicker.value = false
    }
    const onConfirm = (e : string) => {
      console.log(e)
      if (startOrend.value) {
        meetingEndTimeStr.value = dealTime(new Date(e).getTime()).replace('T', ' ')
      } else {
        meetingStartTimeStr.value = dealTime(new Date(e).getTime()).replace('T', ' ')
      }
      showPicker.value = false
    }
    const openTime = (e : number) => {
      showPicker.value = true
      startOrend.value = e
      if (e) {
        currentTime.value = new Date(meetingEndTimeStr.value.replace(/-/g, '/'))
      } else {
        currentTime.value = new Date(meetingStartTimeStr.value.replace(/-/g, '/'))
      }
    }
    const getMeetRoomList = async () => {
      const data = await getMeetingRoomList({ siteId: store.state.siteId })
      tabList.value = data.data
      if (data.data && data.data.length > 0) {
        columns.value = tabList.value.map((el:meetingRoomObj) => {
          return el.meetingRoomName
        })
      }
      // console.log(columns.value)
    }
    const confirmMeetroom = async () => {
      meetingroom.value.validate().then(async () => {
        // 处理会议室
        const meetingRoomId = tabList.value.find((el : meetingRoomObj) => {
          return el.meetingRoomName === meetRoom.value
        }).id

        // console.log('=====', meetingStartTimeStr.value, meetingEndTimeStr.value)
        const openId:any = store.state.openId || window.localStorage.getItem('openid')

        // 验证是否有会议冲突
        const conflictMeetList:any = conflictMeeting({
          appId: openId,
          meetingRoomId,
          meetingStartTimeStr: String(meetingStartTimeStr.value).replace(' ', 'T'),
          meetingEndTimeStr: String(meetingEndTimeStr.value).replace(' ', 'T')
        })
        conflictMeetList.then((res:any) => {
          const conflictMeetList = res
          if (conflictMeetList.data && Array.isArray(conflictMeetList.data) && conflictMeetList.data.length === 0) {
            // 开始时间结束时间
            console.log(meetingStartTimeStr.value.length)
            meetingStartTimeStr.value = meetingStartTimeStr.value.replace(' ', 'T')
            meetingEndTimeStr.value = meetingEndTimeStr.value.replace(' ', 'T')
            createMeeting({
              siteId: store.state.siteId,
              meetingStartTimeStr: meetingStartTimeStr.value, // moment(meetingStartTimeStr.value).format('YYYY-MM-DD HH:mm'),
              meetingRoomId: meetingRoomId,
              meetingName: meetingName.value,
              meetingEndTimeStr: meetingEndTimeStr.value, // moment(meetingEndTimeStr.value).format('YYYY-MM-DD HH:mm'),
              bookingPerson: bookingPerson.value,
              appId: openId,
              tel: tel.value,
              num: meetingNum.value
            }).then((res:any) => {
              if (res.returnCode === '2000') {
                Toast.success('增加会议成功')
                router.back()
                // 记录房间信息
                store.commit('SET_SELECTMRRTINFO', { name: meetRoom.value, id: null, itemid: meetingRoomId })
                console.log(store.state.selectMeetInfo)
              }
            })
          } else {
            Toast.fail('当前时间段会议室已被预订，请重新选择时间')
          }
        })
      })
    }
    return {
      meetingroom,
      confirmMeetroom,
      openTime,
      columns,
      onConfirm,
      meetonConfirm,
      result,
      showPicker,
      showMeetPicker,
      meetRoom,
      bookingPerson,
      meetingName,
      meetingNum,
      meetingStartTimeStr,
      meetingEndTimeStr,
      filter,
      currentTime,
      tel
    }
  }
})
